/**
 * Add / edit content.
 */
import { apiGet, apiPut, apiPost } from '@/util/api';
import axios from 'axios';
import Icon from '@/icons/Icon.vue';

export default {
  name: 'Content',
  components: {
    Icon,
  },
  props: {
    contentId: null,
  },
  data: () => ({
    loading: true,
    content: {
      name: '',
      copyright: '',
      url: '',
      description: '',
      published: undefined,
      author: '',
      tags: [],
      availableForLoginPage: false,
      global: false,
    },
    newImage: null,
    newImagePasted: false,
    imagePreview: '',
    imageValid: null,
    imageValidMessage: '',
    imgElem: null,

    image_16_9: '',
    image_4_3: '',

    availableTags: [],
    tagCategoryOptions: [
      { text: 'Funny', value: 1 },
      { text: 'Useful', value: 2 },
      { text: 'Beautiful', value: 3 },
      { text: 'Inspiring', value: 4 },
      { text: 'Countries', value: 'countries' },
    ],
    selectedTagType: null,
    tagPage: 1,
    tagPerPage: 8,

    noTagState: false,
    noCountryState: false,
  }),
  async created() {
    window.addEventListener('paste', this.onPaste);

    await this.getContent();
    if (!this.contentId) this.setPublished(true);
    this.availableTags = await apiGet('/tag?orderBySort=name&orderByOrder=ASC');

    this.availableTags.forEach(tag => {
      if (tag.category === 'CATEGORY') {
        const category = this.tagCategoryOptions.find(
          t2 => t2.text === tag.name,
        );
        if (category) {
          category.value = tag.id;
          if (category.text === 'Funny') {
            this.selectedTagType = tag.id;
          }
        }
      }
    });
    this.imagePreview = this.content.thumbnail;
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.onPaste);
  },
  watch: {
    newImage: async function() {
      if (this.newImage) {
        // Set the form input valid state.
        this.imageValid = true;

        // Check whether the image was pasted.
        // This is a bit of a fudge - relies on the fact that pasted images have no "path" property.
        this.newImagePasted = !Object.prototype.hasOwnProperty.call(
          this.newImage,
          '$path',
        );

        // Generate the different scale images that we need.
        const image = await this.getImagePreview(this.newImage);
        const { canvas16, canvas4 } = this.$refs;
        this.image_16_9 = await this.cropImage(canvas16, image);
        this.image_4_3 = await this.cropImage(canvas4, image);
      } else {
        this.imageValid = this.contentId ? null : false;

        this.newImagePasted = false;

        this.image_16_9 = null;
        this.image_4_3 = null;
      }

      // Set the preview image.
      this.imagePreview = await this.getImagePreview(this.image_4_3);
    },
  },
  computed: {
    filteredTags: function() {
      return this.availableTags
        .filter(t => t.category !== 'CATEGORY')
        .filter(t => {
          if (this.selectedTagType === 'countries') {
            return t.category === 'COUNTRY';
          } else if (typeof this.selectedTagType === 'number') {
            return t.parentTag === this.selectedTagType;
          }
          return true;
        })
        .map(tag => ({
          ...tag,
          selected: this.content.tags.some(cTag => cTag.id === tag.id),
        }));
    },
  },
  methods: {
    /**
     * Event listener for file paste events.
     */
    async onPaste(event) {
      if (event.clipboardData.files.length > 0) {
        event.preventDefault();
        event.stopPropagation();

        this.newImage = event.clipboardData.files[0];
        this.imageValid = true;
      }
    },

    async saveContent(force) {
      if (!this.contentId && !this.newImage) {
        this.imageValid = false;
        return;
      }
      if (!force) {
        if (
          !this.content.tags.some(t => t.category.toLowerCase() === 'genre')
        ) {
          this.noTagState = true;
        }

        if (
          !this.content.tags.some(t => t.category.toLowerCase() === 'country')
        ) {
          this.noCountryState = true;
        }

        if (this.noTagState || this.noCountryState) return;
      }

      try {
        let response;
        if (this.contentId) {
          response = await apiPut(
            `/content/${this.contentId}${this.newImage ? '?image=true' : ''}`,
            {
              content: {
                name: this.content.name,
                description: this.content.description,
                author: this.content.author,
                url: this.content.url,
                tags: this.content.tags.map(t => t.id),
                availableForLoginPage: this.content.availableForLoginPage,
                global: this.content.global,
                copyright: this.content.copyright,
              },
            },
          );
        } else {
          response = await apiPost('/content', {
            content: {
              ...this.content,
              tags: this.content.tags.map(t => t.id),
            },
          });
        }

        if (response.thumbnail) {
          await axios.put(response.thumbnail, this.image_4_3);
        }

        if (response.backdrop) {
          await axios.put(response.backdrop, this.image_16_9);
        }

        this.$bvToast.toast(
          this.$t('content_edit_save_success', {
            contentName: this.content.name,
          }),
          {
            title: this.$t('common_success_title'),
            variant: 'success',
          },
        );

        this.$router.push({ name: 'contents' });
      } catch {
        this.$bvToast.toast(
          this.$t('content_edit_save_fail', {
            contentName: this.content.name,
          }),
          {
            title: this.$t('common_error_title'),
            variant: 'danger',
          },
        );
      }
    },

    async getImagePreview(x) {
      return new Promise(res => {
        if (x) {
          const reader = new FileReader();
          reader.addEventListener('load', () => {
            res(reader.result);
          });
          reader.readAsDataURL(x);
        } else if (this.content.thumbnail) {
          res(this.content.thumbnail);
        }
      });
    },

    /**
     * Get content.
     */
    async getContent() {
      if (this.contentId) {
        this.loading = true;
        this.content = await apiGet(`/content/${this.contentId}`);
      }
      this.loading = false;
    },

    /**
     * Add all of the country tags to the content.
     */
    addAllCountriesToContent() {
      if (this.content.global) {
        this.availableTags.forEach(t => {
          if (t.category === 'COUNTRY') {
            this.addTagToContent(t);
          }
        });
      }
    },

    /**
     * Add a tag to the content item.
     * @param tag
     */
    addTagToContent(tag) {
      // Prevent a tag from being assigned twice.
      const assignedTag = this.content.tags.find(t => t.id === tag.id);
      if (assignedTag) {
        return false;
      }

      this.content.tags.push(tag);
      const selectedParent = this.content.tags.find(
        cTag => cTag.id === tag.parentTag,
      );

      if (tag.parentTag && !selectedParent) {
        const newTag = this.availableTags.find(
          aTag => aTag.id === tag.parentTag,
        );
        this.content.tags.push({
          ...newTag,
          selected: true,
        });
      }
    },

    /**
     * Remove a tag from the country
     * @param {Object} tag
     */
    async removeTag(tag) {
      if (tag.category === 'COUNTRY') {
        this.content.global = false;
      }
      const index = this.content.tags.findIndex(cTag => cTag.id === tag.id);
      this.content.tags.splice(index, 1);

      this.content.tags = this.content.tags.filter(
        pTag => pTag.parentTag !== tag.id,
      );

      // If the tag being removed is a genre and is the last genre of a category to be removed
      // (i.e. after its removal no further tags in the array share its parent tag)
      if (
        tag.category === 'GENRE' &&
        !this.content.tags.some(t => t.parentTag === tag.parentTag)
      ) {
        // find and remove its parent tag (the category) as well
        const categoryIndex = this.content.tags.findIndex(
          t => t.id === tag.parentTag,
        );
        // categoryIndex should never be -1 but just in case
        if (categoryIndex > -1) this.content.tags.splice(categoryIndex, 1);
      }
    },

    setPublished(checked) {
      const date = new Date().toISOString();
      this.content.published = checked
        ? date.substring(0, date.indexOf('T'))
        : undefined;
    },

    /**
     * Crops image `data` to
     * dimensions defined by `canvas`
     * and exports as jpg
     * @param {HTMLCanvasElement} canvas
     * @param {string} data
     */
    cropImage(canvas, data) {
      return new Promise(res => {
        const ctx = canvas.getContext('2d');
        const { width: dw, height: dh } = canvas;
        const image = new Image();
        image.onload = () => {
          const { naturalWidth: w, naturalHeight: h } = image;
          let _w = dw,
            _h = dh,
            x = 0,
            y = 0;
          if (w / h >= dw / dh) {
            _w = (w * _h) / h;
            x = dw / 2 - _w / 2;
            y = 0;
          } else {
            _h = (h * _w) / w;
            x = 0;
            y = dh / 2 - _h / 2;
          }
          ctx.drawImage(image, x, y, _w, _h);
          canvas.toBlob(res, 'image/jpeg', 0.6);
        };
        image.src = data;
      });
    },
    recheckTags() {
      if (this.content.tags.some(t => t.category.toLowerCase() === 'genre')) {
        this.noTagState = false;
      }

      if (this.content.tags.some(t => t.category.toLowerCase() === 'country')) {
        this.noCountryState = false;
      }
    },
  },
};
